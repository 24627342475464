.isp-plotLegend {
	@include font(legend);
	@include isp-subContainer();
	background-color: $colorBgBase;
	margin-bottom: $base2;
	padding: $base;
	position: relative;

	.isp-plot.isp-js-showMap & {
		display: none;
	}


	&__container {
		display: none;
		flex-direction: column;

		&.isp-js-selected {
			display: block;

			@include media($mediumLayout) {
				@include staticColumns();
			}
		}
	}


	&__entry {
		position: relative;
		padding-left: $base2;
	}


	&__entrySymbol {
		display: inline-block;
		margin-right: $baseHalf;
		position: absolute;
		left: 0;
		top: getCalculatedLineHeight(legend) / 2;
		transform: translateY(-50%);
		width: $base;

		@include media($smallLayout) {
			top: getCalculatedLineHeight(legend, $smallLayout) / 2;
		}

		&--pattern {
			background: repeating-linear-gradient(-45deg, $colorBgBase, $colorBgBase 1px, $colorBase 1px, $colorBase 2.1px);
		}

		.isp-plotLegend__entry--scenario & {
			border-radius: 50%;
			height: $base;
		}

		.isp-plotLegend__entry--shade & {
			height: $base;
		}
	}


	&__section {
		width: 100%;

		@include media($mediumLayout) {
			width: staticColumnSize(1, 3, $gutter);
		}

		.isp-plotLegend__container--temperature & {
			@include media($mediumLayout) {
				width: staticColumnSize(1, 2, $gutter);
			}
		}

		& + & {
			margin-top: $base;

			@include media($mediumLayout) {
				margin-top: 0;
			}
		}
	}


	&__title {
		@include font(legend);
		font-weight: 600;
		margin-bottom: $base;
	}
}
