.isp-searchResults {
	position: relative;

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: $base 0 $base2 0;

		& + & {
			border-top: 1px solid $colorBorderLight;
		}

		&:first-child {
			padding-top: 0;
		}
	}


	&__itemCategory {
		@include font(detail);
		color: $colorDecoration;
		order: 2;
	}


	&__itemConfiguratorLink {
		@include isp-actionLink($colorHighlight, $colorHighlightHover);
		margin-top: $base;
		order: 5;
	}


	&__itemDescription {
		@include font(detail);
		color: $colorBgAlternative;
		order: 4;
	}


	&__itemImage {
		margin-bottom: $base;
		margin-top: $base;
		order: 1;
		width: 100%;
	}


	&__itemImageLink {
		width: 50%;
	}


	&__itemImg {
		width: 100%;
	}


	&__itemTitle {
		@include font(base);
		color: $colorBase;
		font-weight: 600;
		order: 3;
	}


	&__itemTitleLink {
		color: $colorBase;
		text-decoration: none;
	}


	&__items {
		@include isp-subContainer();

		.isp-block--pageMenu + & {
			padding-top: $base5;

			@include media($smallLayout) {
				padding-top: 0;
			}
		}
	}


	&__message {
		@include isp-subContainer();
		@include font(base);
		font-weight: 600;
		color: $colorDecoration;
	}


	&__suggestionItem {
		@include font(detail);

		& + & {
			margin-top: $base;
		}
	}


	&__suggestionLink {
		border-bottom: solid 1px $colorHighlight;
		color: currentColor;
		text-decoration: none;
		padding-bottom: $base / 4;

		@include onHover() {
			color: $colorHighlight;
		}
	}


	&__suggestions {
		@include isp-subContainer();
	}


	&__suggestionsItems {
		.isp-searchResults__suggestionsTitle + & {
			margin-top: $base;
		}
	}


	&__suggestionsTitle {
		@include font(base);
		font-weight: 600;
	}
}
