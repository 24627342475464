.isp-temporaryTextImage {
	display: flex;


	&__title {
		@include font(h3);
		margin-bottom: $base1;

		@include media($smallLayout) {
			margin-bottom: $base2;
		}
	}

	&__item {
		width: staticColumnSize(12, 12, $gutter);


		@include media($smallLayout) {
			margin-right: $gutter;
			width: staticColumnSize(6, 12, $gutter);
		}

		&:not(:last-of-type) {
			margin-bottom: $base3;
		}

		&:nth-child(1n+2) {
			order: 1;

			@include media($smallLayout) {
				order: unset;
			}
		}

		&:nth-child(n+4) {
			order: 3;
			@include media($smallLayout) {
				order: unset;
			}
		}
	}


	&__items {
		display: flex;
		flex-direction: column;
		width: staticColumnSize(12, 12, $gutter);

		@include media($smallLayout) {
			display: block;
			width: staticColumnSize(12, 12, $gutter);
		}
	}


	&__picture {
		display: flex;
		justify-content: center;
		order: 2;
		margin-bottom: $base4;
		margin-top: $base1;
		width: staticColumnSize(12, 12, $gutter);

		@include media($smallLayout) {
			@include clearfix();
			float: right;
			order: unset;
			width: staticColumnSize(6, 12, $gutter);
		}

		img {
			width: 100%;
		}
	}

	&__image {
		width: staticColumnSize(6, 6, $gutter);

		@include media($smallLayout) {
			width: staticColumnSize(4, 6, $gutter);
		}
	}

	// &__img {

	// }

	&__text {
		a {
			@include isp-link();
		}
	}
}
