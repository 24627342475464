.isp-featuredStory {
	@mixin sizeLimit() {
		margin-left: auto;
		margin-right: auto;
		max-width: staticColumnSize(8, 12, $gutter);

		@include media($largeLayout) {
			max-width: staticColumnSize(6, 12, $gutter);
		}
	}

	&__frame {
		width: 100%;
		margin: auto;
		position: relative;
		z-index: 0;
		max-width: staticColumnSize(10, 12, $gutter);

		@include media($smallLayout) {
			max-width: staticColumnSize(6, 12, $gutter);
		}

		@include media($largeLayout) {
			max-width: staticColumnSize(4, 12, $gutter);
		}

		outline: 2000vw solid $colorBgOverlay;


		// &::before {
		// 	@include isp-bgOverlay($top: auto, $bottom: 100%, $left: 50%, $height: 10000vh);
		// 	margin-left: -50vw;
		// }

		// &::after {
		// 	@include isp-bgOverlay($top: 100%, $left: 50%, $height: 10000vh);
		// 	margin-left: -50vw;
		// }
	}


	&__frameLink {
		display: block;
		padding-top: 100%;
		position: relative;


		// &::before {
		// 	@include isp-bgOverlay($left: auto, $right: 100%, $width: 50vw);
		// }


		// &::after {
		// 	@include isp-bgOverlay($left: 100%, $width: 50vw);
		// }
	}


	&__info {
		@include sizeLimit();
		position: relative;
		text-align: center;

		.isp-featuredStory__frame + & {
			margin-top: $base2;
		}
	}


	&__link {
		@include sizeLimit();
		margin-top: $base2;
		position: relative;
		text-align: center;
		z-index: 1;

		&--alternate {
			margin-bottom: $base3;
		}
	}


	&__title {
		@include sizeLimit();
		@include font(h3);
		border-bottom: 1px solid $colorBorderBase;
		padding-bottom: $base3;
		position: relative;
		text-align: center;

		.isp-page--withBg & {
			border-bottom-color: $colorBorderInverted;
		}
	}
}
