.isp-pageMenuOverlay {
	@include fadeOut($pageMenuDuration);
	@include isp-container();
	@include mediaMap($sizeVariants, pageMenuVerticalSpacings);
	display: none;
	position: fixed;
	left: 0;
	z-index: 1;
	bottom: $base2;


	&.isp-js-beforeActive {
		display: block;
	}

	&.isp-js-active {
		@include fadeIn();
	}
}
