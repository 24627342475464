.isp-homeInfographic {
	&__item {
		border-color: currentColor;
		border-style: solid;
		padding: $base2 $base2 $base3 $base2;
		position: relative;

		&:nth-child(1) {
			left: -$base;
			border-width: 1px 1px 0 1px;
			width: calc(100% + #{$base});

			@include media($mediumLayout) {
				left: 0;
				padding: $base3 staticColumnSize(1, 9, $gutter) $base5 staticColumnSize(1, 9, $gutter);
				width: staticColumnSize(9, 12, $gutter);
			}

			@include media($largeLayout) {
				border-width: 1px 0 1px 1px;
				padding: ($base3 + $base4) 0 $base3 staticColumnSize(1, 12, $gutter);
				width: staticColumnSize(4, 12, $gutter);
			}


			&::after {
				@include media($largeLayout) {
					content: '';
					border-bottom: 1px solid currentColor;
					height: 0;
					position: absolute;
					left: 100%;
					top: 100%;
					width: calc(#{$gutter} + 1px);
				}
			}
		}

		&:nth-child(2) {
			background-color: $colorBgHomeInfographic;
			border-width: $homeInfographicBigBorderSize;

			@include media($mediumLayout) {
				margin-left: staticColumnPush(3, 12, $gutter);
				padding: $base3;
				width: staticColumnSize(6, 12, $gutter);
			}

			@include media($largeLayout) {
				margin-left: 0;
				margin-top: $base4;
				padding: $base3;
				width: staticColumnSize(4, 12, $gutter);
			}

			&::before {
				bottom: -$homeInfographicBigBorderSize;
				border-color: currentColor;
				border-style: solid;
				border-width: 0 0 1px 1px;
				content: '';
				height: calc(100% + #{$homeInfographicBigBorderSize * 2});
				left: calc(#{-$base} - #{$homeInfographicBigBorderSize});
				position: absolute;
				width: $base;
				z-index: -1;

				@include media($mediumLayout) {
					left: staticColumnPush(-3, 6, $gutter, -($homeInfographicBigBorderSize * 2));
					width: staticColumnPush(3, 6, $gutter, $homeInfographicBigBorderSize);
				}

				@include media($largeLayout) {
					border-width: 1px 1px 0 0;
					bottom: auto;
					left: auto;
					height: calc(#{$base4} + #{$homeInfographicBigBorderSize});
					top: calc(-#{$base4} - #{$homeInfographicBigBorderSize});
					right: -$homeInfographicBigBorderSize;
					width: calc(100% + #{$gutter} + #{$homeInfographicBigBorderSize * 3});
				}
			}


			&::after {
				top: -$homeInfographicBigBorderSize;
				border-color: currentColor;
				border-style: solid;
				border-width: 1px 1px 0 0;
				content: '';
				height: calc(100% + #{$homeInfographicBigBorderSize * 2});
				right: calc(#{-$base} - #{$homeInfographicBigBorderSize});
				position: absolute;
				width: $base;
				z-index: -1;

				@include media($mediumLayout) {
					right: staticColumnPush(-3, 6, $gutter, -($homeInfographicBigBorderSize * 2));
					width: staticColumnPush(3, 6, $gutter, $homeInfographicBigBorderSize);
				}

				@include media($largeLayout) {
					border-width: 0 0 1px 1px;
					top: auto;
					right: auto;
					height: calc(#{$base4} + #{$homeInfographicBigBorderSize});
					bottom: calc(-#{$base4} - #{$homeInfographicBigBorderSize});
					left: -$homeInfographicBigBorderSize;
					width: calc(100% + #{$gutter} + #{$homeInfographicBigBorderSize * 3});
				}

			}
		}

		&:nth-child(3) {
			border-width: 0 1px 1px 1px;
			width: calc(100% + #{$base});

			@include media($mediumLayout) {
				margin-left: staticColumnPush(3, 12, $gutter);
				padding: $base3 staticColumnSize(1, 9, $gutter) $base5 staticColumnSize(1, 9, $gutter);
				width: staticColumnSize(9, 12, $gutter);
			}

			@include media($largeLayout) {
				border-width: 1px 1px 1px 0;
				margin-left: 0;
				padding: $base3 staticColumnSize(1, 12, $gutter) ($base3 + $base4) 0;
				top: $base4;
				width: staticColumnSize(4, 12, $gutter);
			}


			&::before {
				@include media($largeLayout) {
					content: '';
					border-top: 1px solid currentColor;
					height: 0;
					position: absolute;
					right: 100%;
					bottom: 100%;
					width: calc(#{$gutter} + 1px);
				}
			}
		}
	}


	&__itemContent {
		.isp-homeInfographic__item:nth-child(2) & {
			@include media($mediumLayout) {
				@include clearfix();
			}


			&::before {
				@include media($mediumLayout) {
					content: '';
					display: block;
					float: left;
					padding-bottom: 100%;
					width: 0;
				}
			}
		}
	}


	&__itemText {
		@include font(base);
		width: 100%;

		.isp-homeInfographic__itemTitle + & {
			margin-top: $base;
		}
	}


	&__itemTitle {
		@include font(h3);
		width: 100%;
	}


	&__items {
		@include media($largeLayout) {
			@include staticColumns();
		}
	}
}
