.isp-footer {
	@include isp-container();
	padding-left: 0;
	padding-right: 0;


	&__content {
		background-color: $colorBgBase;
		padding: $base2 $base2 $base4 $base2;


		@include media($smallLayout) {
			padding: $base4 $base3 $base6 $base3;
		}
	}


	&__icon {
		color: $colorFooterDecorations;
		display: flex;
		justify-content: center;

		span [data-type="svg"] {
			fill: currentColor;
			stroke: currentColor;
		}

		@include media($smallLayout) {
			display: none;
		}
	}


	&__menuItem {
		@include font(baseVariant);
		padding-bottom: $base1 / 2;

		@include media($smallLayout) {
			padding-bottom: 0;
		}
	}


	&__menuItems {
		@include ease(opacity visibility, $durationCollapsable);
		display: flex;
		flex-direction: column;
		padding: $baseHalf 0;
		position: relative;
		left: 0;
		opacity: 1;

		@include media($smallLayout) {
			padding: $base 0;
		}

		&.isp-js-collapsed {
			position: absolute;
			opacity: 0;
			visibility: hidden;

			@include media($smallLayout) {
				position: relative;
				opacity: 1;
				visibility: inherit;
			}
		}
	}


	&__menuLink {
		@include font(detailVariant);
		color: $colorBase;
		text-decoration: none;

		.isp-footer__menuItems.isp-js-collapsed & {
			user-select: none;
		}
	}


	&__menuSection {
		@include ease(height, $durationCollapsable);
		border-bottom: 1px solid $colorFooterDecorations;
		margin-right: $gutter;
		padding: $base2 0 $base1 0;
		position: relative;
		overflow: hidden;
		width: staticColumnSize(12, 12, $gutter);

		@include media($smallLayout) {
			border: none;
			width: staticColumnSize(3, 12, $gutter);
		}
	}



	&__menuSections {
		display: flex;
		flex-direction: column;

		@include media($smallLayout) {
			flex-direction: row;
		}

		& .isp-footer__menuSection:first-child {
			@include media($smallLayout) {
				margin-left: staticColumnSize(1, 12, $gutter);
			}
		}
	}


	&__title {
		font-family: $fontFamilyAlternate;
		flex-grow: 1;
	}


	&__togglerLink {
		display: flex;
		color: $colorBase;
		text-decoration: none;

		@include media($smallLayout) {
			pointer-events: none;
		}

		&:focus {
			outline: none;
		}
	}
}
