.isp-select {
	@include font(filter);
	display: inline-block;
	min-width: $filterMinWidth;
	position: relative;

	@include media($mediumLayout) {
		margin-left: $base2;
		margin-top: $base2;

		&:first-child {
			margin-left: 0;
		}
	}

	@include media($largeLayout) {
		margin-left: $base2;

		&:first-child {
			margin-left: 0;
		}
	}

	&.isp-js-disabled {
		pointer-events: none;
	}

	&--climateModel {
		z-index: 3;
	}

	&--impactModel {
		z-index: 2;
	}

	&--scenario {
		z-index: 4;
	}

	&--selector {
		display: none;
	}



	&__input {
		@include visuallyHidden();
	}


	&__inputIcon {
		color: $colorInputBase;
		height: $base2;
		left: $base;
		line-height: 0;
		margin-top: -$base;
		position: absolute;
		top: getCalculatedLineHeight(filter) / 2 + $base;
		width: $base2;
		z-index: 0;

		.isp-plot .isp-select--scenario & {
			color: inherit;
		}

		@include media($smallLayout) {
			top: getCalculatedLineHeight(filter, $smallLayout) / 2 + $base;
		}

		&--default {
			.isp-select__input:not(:disabled) ~ .isp-select__label:hover & {
				color: $colorInputHover;

				.isp-plot .isp-select--scenario & {
					color: inherit;
				}
			}

			.isp-select__input:checked ~ .isp-select__label & {
				display: none;
			}
		}

		&--checked {
			color: $colorInputChecked;
			display: none;

			.isp-plot .isp-select--scenario & {
				color: inherit;
			}

			.isp-select__input:not(:disabled) ~ .isp-select__label:hover & {
				color: $colorInputCheckedHover;

				.isp-plot .isp-select--scenario & {
					color: inherit;
				}
			}

			.isp-select__input:checked ~ .isp-select__label & {
				display: block;
			}
		}

		.isp-select--scenario input[value="historical"] ~ & {
			display: none;
		}


		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__item {
		display: block;
		position: relative;
		transform: translateY(-2px);
	}


	&__items {
		background-color: $colorFilterBgItems;
		border-top: 1px solid $colorFilterBorder;
		left: 0;
		position: relative;
		top: 0;
		width: 100%;

		&.isp-js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}

		@include media($mediumLayout) {
			@include ease(opacity visibility, $duration2);
			background-color: $colorBgBase;
			border: 1px solid $colorFilterBorder;
		}
	}


	&__itemsContainer {
		left: 0;
		overflow: hidden;
		position: relative;
		top: 100%;
		width: 100%;

		@include media($mediumLayout) {
			@include ease(height, $duration2);
			margin-top: $base;
			position: absolute;
			transition: none;
		}
	}


	&__label {
		border-top: 1px solid $colorFilterSeparator;
		display: block;
		padding: $base $base $base $base4;
		position: relative;
		z-index: 1;

		.isp-select__input:disabled ~ & {
			display: none;
		}

		.isp-select__input:not(:disabled) ~ &:hover {
			cursor: pointer;
		}
	}


	&__labelValue {
		color: $colorBase;
		display: inline-block;

		.isp-select__input:not(:disabled) ~ .isp-select__label:hover & {
			color: $colorHighlightHover;
			border-bottom-color: $colorCustomIconHighlight;
		}
	}


	&__toggler {
		width: 100%;
	}


	&__togglerLabel {
		//
	}
}
