.isp-miniConfigurator {
	&__action {
		@include isp-subContainer();
	}


	&__control {
		@include font(configuratorControl);
		display: block;
		flex-grow: 0;
		flex-shrink: 0;
		height: auto;
		position: relative;

		& + & {
			margin-top: $base;
		}
	}


	&__controls {
		@include isp-subContainer();
		margin-bottom: $base3;
	}


	&__icon {
		color: $colorInputBaseInverted;
		height: $base2;
		left: 0;
		line-height: 0;
		margin-top: -$base;
		position: absolute;
		top: getCalculatedLineHeight(configuratorControl) / 2;
		width: $base2;
		z-index: 0;

		@include media($smallLayout) {
			top: getCalculatedLineHeight(configuratorControl, $smallLayout) / 2;
		}

		&--default {
			.isp-miniConfigurator__input:not(:disabled) ~ .isp-miniConfigurator__label:hover & {
				color: $colorInputHover;
			}

			.isp-miniConfigurator__input:checked ~ .isp-miniConfigurator__label & {
				display: none;
			}
		}

		&--checked {
			color: $colorInputChecked;
			display: none;

			.isp-miniConfigurator__input:not(:disabled) ~ .isp-miniConfigurator__label:hover & {
				color: $colorInputCheckedHover;
			}

			.isp-miniConfigurator__input:checked ~ .isp-miniConfigurator__label & {
				display: block;
			}
		}


		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__input {
		@include visuallyHidden();
	}


	&__label {
		display: inline-block;
		padding-left: $base3;
		position: relative;
		z-index: 1;

		.isp-miniConfigurator__input:disabled ~ & {
			opacity: $inputDisabledOpacityInverted;
		}

		.isp-miniConfigurator__input:not(:disabled):not(:checked) ~ &:hover {
			cursor: pointer;
		}
	}


	&__labelValue {
		border-bottom: 1px solid transparent;
		display: inline-block;
		padding-bottom: $baseHalf;

		.isp-miniConfigurator__input:checked ~ .isp-miniConfigurator__label & {
			border-bottom-color: $colorInputChecked;
			font-weight: 600;
		}

		.isp-miniConfigurator__input:not(:disabled):not(:checked) ~ .isp-miniConfigurator__label:hover & {
			color: $colorHighlightHover;
			border-bottom-color: $colorCustomIconHighlight;
		}
	}
}
