.isp-searchForm {
	@include isp-subContainer();


	&__button {
		&--reset {
			bottom: 0;
			color: $colorCoverCategory;
			position: absolute;
			right: 0;
		}


		&--submit {
			margin-top: $base2;


			@include media($largeLayout) {
				bottom: getCalculatedLineHeight(h1) / 2;
				left: 100%;
				margin-left: $base3;
				margin-top: 0;
				position: absolute;
				transform: translateY(50%);


				@include media($smallLayout) {
					bottom: getCalculatedLineHeight(h1, $smallLayout) / 2;
				}
			}
		}
	}


	&__content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
	}


	& &__input {
		@include font(h1);
		background-color: transparent;
		border: 0;
		color: $colorInverted;
		display: block;
		padding-right: $base3;
		width: 100%;
	}


	&__inputBox {
		position: relative;
		width: 100%;
	}


	&__label {
		@include font(base);
		color: $colorCoverCategory;
		display: block;
		width: 100%;
	}
}
