.isp-plot {
	// padding-bottom: $gutter;
	padding-top: $gutter;
	position: relative;

	&::before {
		background-color: $colorBgPlot;
		content: '';
		position: absolute;
		width: 100vw;
		height: 100%;
		left: 50%;
		margin-left: -50vw;
		top: 0;
		z-index: 0;
		// @include isp-subContainer();
	}

	&:not(.isp-js-expanded)::before {
		@include media($largeLayout) {
			width: auto;
			margin-left: 0;
			left: staticColumnSize(3, 12, $gutter);
			right: staticColumnSize(2, 12, $gutter);
		}
	}


	&__container {
		position: relative;

		.isp-plot:not(.isp-js-expanded) & {
			@include media($largeLayout) {
				@include isp-subContainer();
			}
		}


		&::before {
			@include font(base);
			align-items: center;
			background-color: $colorBgBase;
			color: $colorHighlight;
			content: 'loading...';
			display: flex;
			flex-direction: row;
			font-weight: 600;
			justify-content: center;
			left: 0;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}

		&.isp-js-loaded::before {
			display: none;
		}
	}


	&__core {
		--plot-dot-size: 5;
		--plot-dot-hover-size: 5;
		--plot-dot-border-width: 0.0001; // 0 doesn’t seem to turn it off :-(
		--plot-font-size: 16; // no units
		background-color: #F1F4F4;
		color: $colorBase;
		position: relative;
		height: 55vh;
		min-height: 350px;
		max-height: 550px;
		margin-bottom: $base3;
		z-index: 1;

		@include media($smallLayout) {
			height: 55vh;
		}

		.isp-plot.isp-js-showMap & {
			visibility: hidden;
		}
	}


	&__dataLink {
		@include isp-actionLink($color: $colorHighlight, $hoverColor: $colorHighlightHover);
		cursor: pointer;

		&:not(:last-child) {
			margin-right: $base2;
		}
	}


	&__dataLinks {
		@include isp-subContainer();
		margin-top: $gutter;
		padding-top: $base;
		position: relative;

		&::before {
			background-color: $colorBgBase;
			content: '';
			height: 100%;
			left: 50%;
			margin-left: -75vw;
			position: absolute;
			top: 0;
			width: 150vw;
		}
	}


	&__downloadButton {
		display: none;
		position: absolute;
		top: $base2;
		right: $base2;
		z-index: 4;

		.isp-plot:not(.isp-js-showMap) .isp-plot__container.isp-js-rendered & {
			display: inline-flex;
		}

		.isp-plot.isp-js-expanded & {
			margin-left: 0;
		}
	}


	&__expandButton {
		display: none;
		position: absolute;
		top: 50%;
		left: 100%;
		margin-left: $base4;
		transform: translateY(-50%);

		.isp-plot:not(.isp-js-showMap) & {
			@include media($largeLayout) {
				display: inline-flex;
			}
		}

		.isp-plot.isp-js-expanded & {
			margin-left: 0;
		}
	}


	&__viewsContainer {
		position: relative;
	}
}
