.isp-modal {
	@include storeValuesForJs((enableScrollMonitor: false));
	@include fadeOut($duration4);
	background-color: $colorBgModal;
	display: none;
	height: 100vh;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 100;

	&.isp-js-fixed {
		position: fixed;
	}


	&.isp-js-beforeActive {
		@include media($largeLayout) {
			display: block;
			will-change: opacity, visibility;
		}
	}

	&.isp-js-duringActive {
		@include media($largeLayout) {
			@include fadeIn();
		}
	}


	&__content {
		@include media($largeLayout) {
			@include isp-container();
			margin-top: $base1 * 10;
			height: 100%;
			position: relative;
		}
	}


	&__item {
		height: 100%;
		position: relative;
		width: 100%;
	}


	&__toggler {
		cursor: pointer;
		top: 0;
		position: absolute;
		width: $modalIconSpacing;
		z-index: 200;

		right: $containerPaddingSmall;

		@include media($smallLayout) {
			right: $containerPaddingMedium;
		}

		@include media($largeLayout) {
			right: $containerPaddingLarge;
		}
	}
}
