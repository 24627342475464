// .isp-page__blocks & {
// 	// page
// }

// .isp-glossary__blocks & {
// 	// overlay
// }

.isp-glossaryNav {
	@include font(detailVariant);
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	height: calc(100vh - (#{$headerHeightSmall} + #{$base2}));
	justify-content: space-between;
	margin-right: -$glossarySideSpacing;
	right: 0;
	width: $base6;
	z-index: 2;

	.isp-html.ie & {
		position: relative;
		top: 0;
	}

	.isp-page__blocks & {
		position: sticky;
		top: $headerHeightSmall;
		height: 100%;

		@include media($smallLayout) {
			align-items: center;
			background-color: $colorInverted;
			flex-direction: row;
			height: auto;
			margin-left: -$base4;
			padding-bottom: $base2;
			padding-left: $base4;
			top: $headerHeightMedium;
			width: auto;
		}

		@include media($largeLayout) {
			justify-content: flex-start;
		}

		.isp-body:not([data-isp-current-context='default']) & {
			// prevent to be sticky when context is open on glossary page
			position: static;
		}
	}


	.isp-glossary__blocks & {
		background: $colorMenuBg;
		padding-right: $containerPaddingSmall;
		position: fixed;
		top: $headerHeightSmall;
		padding-bottom: $base2;

		@include media($smallLayout) {
			flex-direction: column;
			height: calc(100vh - (#{$headerHeightMedium} + #{$base6}));
			margin-right: $containerPaddingMedium;
			padding-right: 0;
			top: $headerHeightMedium + $base6;
		}

		@include media($mediumLayout) {
			align-items: center;
			flex-direction: row;
			height: auto;
			margin-left: -$base4;
			padding-left: $base4;
			position: sticky;
			top: $headerHeightMedium;
			width: auto;
		}

		@include media($largeLayout) {
			justify-content: flex-start;
		}
	}


	&__letters {
		@include font(navDetail);
		text-align: right;
		width: 100%;

		@include media($smallLayout) {
			// NOTE: fixing height because different subpixel calc on lineHeight in Safari. 1.9998px | safari = 1.9px, other = 2.0px
			height: round(getFontSize(detailVariant, $smallLayout) * getLineHeight(detailVariant, $smallLayout));
		}

		// NOTE: for scroll indication feature
		// .isp-page__blocks & {
		// 	&:not(.isp-glossaryNav__letters--disabled) {
		// 		font-weight: bold;
		// 	}
		// }

		.isp-page__blocks & {
			@include media($smallLayout) {
				width: auto;
			}

			@include media($largeLayout) {
				margin-right: $base2;
			}
		}


		.isp-glossary__blocks & {
			@include media($smallLayout) {
				margin-right: -$base2;
			}

			@include media($mediumLayout) {
				width: auto;
			}

			@include media($largeLayout) {
				margin-right: $base2;
			}
		}
	}


	&__button {
		cursor: pointer;
		text-align: center;
		text-decoration: none;
		width: 100%;

		.isp-glossary__blocks & {
			color: $colorInverted;

			&.isp-js-disabled {
				pointer-events: none;
				color: $colorGlossaryNavDisabled;
			}
		}

		.isp-page__blocks & {
			color: $colorBase;

			&.isp-js-disabled {
				pointer-events: none;
				color: $colorGlossaryNavDisabled;
			}
		}
	}
}
