.isp-slideshowNavigation {
	.isp-section--cover & {
		padding-bottom: $base3;

		@include media($largeLayout) {
			padding-bottom: $base5;
		}
	}


	&__icon {
		@include ease(background-color, $duration2);
		color: $colorInverted;
		display: inline-block;
		position: relative;

		.isp-slideshowNavigation__item--prev & {
			transform: rotate(180deg);
		}

		.isp-slideshowNavigation__link.isp-js-current & {
			background-color: currentColor;
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__item {
		cursor: pointer;
		line-height: 0;
		margin-right: $base2;
		width: $modalIconSpacing;

		&:last-child {
			margin-right: 0;
		}

		&--prev,
		&--next {
			html.no-js & {
				display: none;
			}
		}
	}


	&__items {
		margin-right: $modalIconSpacing + $base4;
		display: flex;
		flex-direction: row;
		pointer-events: none;
		justify-content: space-between;
	}


	&__link {
		display: block;
		pointer-events: all;
		cursor: pointer;
	}
}
