.isp-plotTooltip {
	@include font(detailVariant);
	opacity: 1;
	transition: opacity 0.2s;
	position: absolute;
	pointer-events: none;
	z-index: 1;
	background-color: $colorBgSearch;
	border: solid 1px $colorBorderLight;
	padding: 9px 9px;

	&__line {
		& + & {
			margin-top: 2px;
		}
	}

	&__label {
		color: $colorDecoration;
		white-space: nowrap;
	}

	&__value {
		white-space: nowrap;
	}

	&::after,
	&::before {
		content: " ";
		top: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}
}
