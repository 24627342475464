.isp-countryRankingMap {
	@include ease(opacity visibility, $duration2);
	background-color: $colorBgPlot;
	display: block;
	// flex-direction: column;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	visibility: hidden;
	width: 100%;
	z-index: 2;

	@include media($largeLayout) {
		left: staticColumnPush(3, 12, $gutter);
		max-width: staticColumnSize(7, 12, $gutter);
	}


	&.isp-js-visible {
		opacity: 1;
		visibility: inherit;
	}


	&__canvas {
		position: absolute;
		left: 50%;
		top: 50%;
		// width: 100%;
		// height: 100%;
		transform: translateX(-50%) translateY(-50%);
	}


	&__imageContainer {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		svg {
			width: 100%;
			height: 100%;

			path {
				fill: none;
				stroke: $colorBase;
				stroke-width: 2px;
				vector-effect: non-scaling-stroke;
			}
		}
	}


	&__layers {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		// border: 1px solid black;
	}


	&__map {
		position: relative;
		width: 100%;
		height: 100%;
	}
}
