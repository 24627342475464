// common functions, mixins
@import "common/functions/index";
@import "common/mixins/index";
@import "common/generic/index";

// project config
@import "isp/config/index";
@import "isp/config/font-faces";

// media queries mixin
@import "include-media/dist/_include-media";

// project animations, functions, mixins
@import "isp/functions/index";
@import "isp/mixins/index";
@import "isp/animations/index";

@import "leaflet/dist/leaflet.css";


// project blocks
@import "isp/blocks/action-link";
@import "isp/blocks/articles-slider.scss";
@import "isp/blocks/call-to-action";
@import "isp/blocks/configurator";
@import "isp/blocks/configurator-control";
@import "isp/blocks/configurator-map";
@import "isp/blocks/configurator-search";
@import "isp/blocks/configurator-section";
@import "isp/blocks/configurator-summary";
@import "isp/blocks/content-info";
@import "isp/blocks/country-details";
@import "isp/blocks/country-ranking-map";
@import "isp/blocks/data-filters";
@import "isp/blocks/editorial-page-list";
@import "isp/blocks/external-video";
@import "isp/blocks/featured-story";
@import "isp/blocks/footer";
@import "isp/blocks/gallery";
@import "isp/blocks/glossary";
@import "isp/blocks/glossary-list";
@import "isp/blocks/glossary-nav";
@import "isp/blocks/home-infographic";
@import "isp/blocks/image";
@import "isp/blocks/indication";
@import "isp/blocks/link-reference";
@import "isp/blocks/main-menu";
@import "isp/blocks/map-legend";
@import "isp/blocks/mini-configurator";
@import "isp/blocks/modal";
@import "isp/blocks/modal-toggler";
@import "isp/blocks/page-menu";
@import "isp/blocks/page-menu-overlay";
@import "isp/blocks/pagination";
@import "isp/blocks/plot";
@import "isp/blocks/plot-legend";
@import "isp/blocks/plot-switch";
@import "isp/blocks/plot-tooltip";
@import "isp/blocks/ranking-graph";
@import "isp/blocks/ranking-info";
@import "isp/blocks/ranking-map";
@import "isp/blocks/search";
@import "isp/blocks/search-form";
@import "isp/blocks/search-results";
@import "isp/blocks/select";
@import "isp/blocks/slide";
@import "isp/blocks/slideshow";
@import "isp/blocks/slideshow-navigation";
@import "isp/blocks/sync";
@import "isp/blocks/text";
@import "isp/blocks/tooltip";
@import "isp/blocks/ugc";


//temporary blocks
@import "isp/blocks/temporary-text-image";
@import "isp/blocks/temporary-text-link";
