.isp-modalToggler {

	&:focus {
		outline: none;
	}


	&__icon {
		background-color: transparent;
		color: $colorInverted;
		text-indent: -10000px;
		// transform: translateY(#{$menuTogglerLineSpace + $menuTogglerLineSize});
	}
}
