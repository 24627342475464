.isp-rankingInfo {
	position: relative;

	.isp-rankingMap & {
		margin-bottom: $base2;

		@include media($smallLayout) {
			margin-bottom: 0;
		}
	}

	.isp-plot & {
		@include isp-subContainer();
		margin-bottom: $base2;
	}


	&__download {
		flex-grow: 0;
		flex-shrink: 0;
		margin-left: $base;
	}


	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.isp-plot & {
			justify-content: flex-start;
		}
	}


	&__info {
		@include font(detail);

		.isp-plot & {
			@include media($mediumLayout) {
				@include dynamicColumns($hSpace: $gutter);
			}
		}
	}


	&__infoEntry {
		&.isp-js-hidden {
			display: none;
		}

		.isp-plot & {
			@include media($mediumLayout) {
				width: dynamicColumnSize(1, 3, $gutter);
			}
		}
	}


	&__infoName {
		color: $colorDecoration;
		display: inline;
		margin: 0;


		&::after {
			content: ':';
		}
	}


	&__infoValue {
		display: inline;
		margin: 0;
		text-indent: 0;
	}


	&__title {
		@include font(base);
		font-weight: 600;
	}
}
