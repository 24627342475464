.isp-articlesSlider {
	@include font(base);
	display: flex;
	flex-direction: column;
	align-items: center;


	&__articleTitle {
		padding-bottom: $base2;
		border-bottom: 1px solid $colorBase;

		.isp-page--withBg & {
			border-bottom: 1px solid $colorInverted;
		}
	}


	&__caption {
		@include font(detail);
		background-color: transparentize($colorBase, 0.4);
		bottom: 0;
		padding: 0 $base1;
		position: absolute;
	}


	&__content {
		& > *:not(:first-child):not(.isp-articlesSlider__articleTitle) {
			padding-top: $base2;
		}

		& .isp-articlesSlider__details:first-child {
			border-top: 1px solid $colorBase;
			padding-top: $base2;
		}

		.isp-page--withBg & .isp-articlesSlider__details:first-child {
			border-top: 1px solid $colorInverted;
			padding-top: $base2;
		}
	}

	&__contentLink {
		color: $colorBase;
		text-decoration: none;

		.isp-page--withBg & {
			color: $colorInverted;
		}

		&:focus {
			outline: none;
		}
	}


	&__details {
		@include font(detail);
		margin-bottom: 0;
	}


	&__image {
		pointer-events: none;
		position: relative;
	}


	&__img {
		width: 100%;
	}


	&__indication {
		margin-bottom: $base1;
	}


	&__items {
		@include staticColumns();
		user-select: none;
		text-decoration: none;
	}


	&__item {
		width: calc(100% - #{$base4});

		&:not(:last-of-type) {
			margin-right: $base2;
		}

		&:last-of-type {
			padding-right: $containerPaddingSmall;

			@include media($mediumLayout) {
				padding-right: $containerPaddingMedium;
			}

			@include media($largeLayout) {
				padding-right: $containerPaddingLarge;
			}
		}

		@include media($mediumLayout) {
			width: staticColumnSize(5, 12, $gutter);
		}

		@include media($largeLayout) {
			width: staticColumnSize(3, 12, $gutter);
		}
	}


	&__link {
		@include isp-link();
		display: inline-block;
		padding-top: $base2;
		padding-bottom: $base1 / 2;
		margin-bottom: $base3;
	}

	&__nav {
		margin-right: -$containerPaddingSmall;
		overflow: hidden;
		position: relative;
		width: calc(100% + #{$containerPaddingSmall});

		@include media($smallLayout) {
			margin-right: -$containerPaddingMedium;
			width: calc(100% + #{$containerPaddingMedium});
		}

		@include media($largeLayout) {
			margin-right: 0;
			width: 100%;
		}
	}

	&__title {
		margin-bottom: $base1;
	}

	&__text {
		@include font(detail);
	}
}
