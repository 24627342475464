.isp-image {
	@include isp-subContainer($pushing: true);
	width: 100%;

	&--alternateLayout {
		@include isp-subContainer($pushing: false);
	}

	&--slide {
		margin: 0;
		height: 100%;
		max-width: 100%;
	}


	&__caption {
		@include font(detail);
		color: $colorImgCaption;
		margin-top: $base1 / 2;

		.isp-page--withBg & {
			color: $colorImgCaptionInverted;
		}
	}

	&__img {
		width: 100%;

		.isp-image--slide & {
			height: 100%;
			@include objectFit(contain, center);
		}

		&--alternate {
			width: 100%;
			@include media($mediumLayout) {
				width: staticColumnSize(4, 8, $gutter);
			}
		}
	}
}
