.isp-plotSwitch {
	$labelWidth: $base10;

	@include isp-subContainer();
	@include font(legend);
	margin-bottom: $base2;
	position: relative;


	&__label {
		font-weight: 600;
		left: 0;
		position: absolute;
		top: $base;
		width: $labelWidth;
	}


	&__option {
		@include font(legend);
		position: relative;
		flex-grow: 0;
		flex-shrink: 0;
		margin-left: $base2;
		margin-top: $base;

		&:first-child {
			margin-left: 0;
		}
	}


	&__optionInput {
		@include visuallyHidden();
	}


	&__optionIcon {
		color: $colorInputBase;
		height: $base2;
		left: 0;
		line-height: 0;
		margin-top: -$base;
		position: absolute;
		top: getCalculatedLineHeight(legend) / 2;
		width: $base2;
		z-index: 0;

		@include media($smallLayout) {
			top: getCalculatedLineHeight(legend, $smallLayout) / 2;
		}

		&--default {
			.isp-plotSwitch__optionInput:not(:disabled) ~ .isp-plotSwitch__optionLabel:hover & {
				color: $colorInputHover;
			}

			.isp-plotSwitch__optionInput:checked ~ .isp-plotSwitch__optionLabel & {
				display: none;
			}
		}

		&--checked {
			color: $colorInputChecked;
			display: none;

			.isp-plotSwitch__optionInput:not(:disabled) ~ .isp-plotSwitch__optionLabel:hover & {
				color: $colorInputCheckedHover;
			}

			.isp-plotSwitch__optionInput:checked ~ .isp-plotSwitch__optionLabel & {
				display: block;
			}
		}


		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__optionLabel {
		display: block;
		padding-left: $base3;
		position: relative;
		z-index: 1;

		.isp-plotSwitch__optionInput:disabled ~ & {
			display: none;
		}

		.isp-plotSwitch__optionInput:not(:disabled):not(:checked) ~ &:hover {
			cursor: pointer;
		}
	}


	&__optionLabelValue {
		display: inline-block;

		.isp-plotSwitch__optionInput:not(:disabled):not(:checked) ~ .isp-plotSwitch__optionLabel:hover & {
			color: $colorHighlightHover;
			border-bottom-color: $colorCustomIconHighlight;
		}
	}


	&__options {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-left: ($labelWidth - $base1);
		margin-top: -$base;
	}
}
