.isp-tooltip {
	@include ease(opacity visibility transform, $duration2);
	@include font(filter);
	background-color: $colorBgBase;
	border: 1px solid $colorBorderLight;
	left: 0;
	opacity: 0;
	padding: $base $base $base $base4;
	position: absolute;
	top: 0;
	visibility: hidden;
	z-index: 10;

	&.isp-js-show {
		opacity: 1;
		visibility: inherit;
	}


	&__color {
		border-radius: 50%;
		left: $base;
		height: $base2;
		position: absolute;
		top: $base;
		width: $base2;
	}


	&__title {
		font-weight: 600;
	}


	&__valueLabel {
		color: $colorDecoration;
	}
}
