.isp-slideshow {
	position: relative;
	touch-action: pan-y;

	.isp-modal & {
		display: none;
		padding: $base4 0;

		&.isp-js-active {
			display: block;
			height: 100%;
		}
	}


	&__navigation {
		margin: 0;
		position: absolute;
		right: 0;
		top: 0;
	}


	&__slide {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.isp-modal & {
			padding-bottom: $base4;
		}
	}


	&__slides {
		// @include aspectRatio($gallerySlidesRatio);
		position: relative;
		margin-bottom: $base3;

		.isp-section--cover &,
		.isp-modal & {
			height: 100%;
			margin-bottom: 0;

			&::before {
				display: none;
			}
		}
	}
}
