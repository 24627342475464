.isp-ugc {
	// User Generated Content Style

	a {
		border-bottom: solid 1px $colorHighlight;
		color: currentColor;
		text-decoration: none;
		padding-bottom: $base1 / 4;

		@include onHover() {
			color: $colorHighlight;
		}
	}


	& &__link {
		&--action {
			@include isp-actionLink($colorHighlight, $colorHighlightHover);
		}
	}


	h1 {
		@include font(h1);
	}


	h2 {
		@include font(h2);
	}


	h3,
	h4,
	h5,
	h6 {
		@include font(h3);
	}


	// space before the headings
	* + h1,
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6 {
		margin-top: $base3;
	}


	// space before all common elements in the content
	* + p,
	* + blockquote,
	* + figure,
	* + ol,
	* + ul,
	* + dl,
	* + table,
	dd + dd,
	dt + dt,
	li + li {
		margin-top: $base;
	}


	// double space only after lists
	ul + *,
	ol + * {
		margin-top: $base2;
	}


	q,
	blockquote {
		quotes: "\00BB" "\00AB";
	}



	blockquote {
		font-weight: normal;
		margin: $base2 0 0 0;
		padding-left: $base3;
		border-left: solid 1px $colorQuoteBorder;

		> p:first-child::before {
			content: open-quote;
		}

		> p:last-of-type::after {
			content: close-quote;
		}
	}

	ol,
	ul {
		margin-left: $base2;

		@include media($smallLayout) {
			margin-left: 0;
		}
	}


	ol {
		margin-left: 0;
		counter-reset: item;

		@include media($smallLayout) {
			margin-left: -$base2;
		}
	}


	ol li {
		display: block;
	}


	ol li::before {
		content: counter(item) ". ";
		counter-increment: item;
		color: $colorListChars;

		.isp-page--withBg & {
			color: $colorDividerInverted;
		}
	}


	ul > li {
		&::before {
			$diamondHeight: $base1 / 3 * 2;
			content: '';
			display: block;
			background-color: $colorListChars;
			left: -$base2;
			position: absolute;
			// top: getFontSize('base') * getLineHeight('base') / 2 - $base1 / 5;
			transform-origin: 0 50%;
			transform: rotate(45deg);
			width: $diamondHeight;
			height: $diamondHeight;

			top: getFontSize('base') * getLineHeight('base') / 2 - getFontSize('base') / 2 + $diamondHeight / 2;

			@include media($smallLayout) {
				top: getFontSize('base', $smallLayout) * getLineHeight('base', $smallLayout) / 2 - getFontSize('base', $smallLayout) / 2 + $diamondHeight / 2;
			}

			.isp-page--withBg & {
				color: $colorDividerInverted;
			}
		}
	}


	li {
		margin-left: 0;
		margin-right: 0;
		position: relative;
	}


	li > ul {
		margin-left: $base2;
	}


	li > ol {
		margin-left: 0;
	}


	li > ul > li {
		&::before {
			$circleHeight: $base1 / 3 * 2;
			content: '';
			display: block;
			border-radius: $circleHeight;
			background-color: $colorListChars;
			left: -$base2;
			position: absolute;
			width: $circleHeight;
			height: $circleHeight;
			top: getFontSize('base') * getLineHeight('base') / 2 - getFontSize('base') / 2 + $circleHeight / 2;

			@include media($smallLayout) {
				top: getFontSize('base', $smallLayout) * getLineHeight('base', $smallLayout) / 2 - getFontSize('base', $smallLayout) / 2 + $circleHeight / 2;
			}


			.isp-page--withBg & {
				color: $colorDividerInverted;
			}
		}
	}


	hr {
		background-color: $colorDivider;
		border: 0;
		height: 1px;
		margin: $base5 0 $base1 * 10 0;
		width: 100%;

		.isp-page--withBg & {
			background-color: $colorDividerInverted;
		}
	}


	img {
		width: 100%;
	}


	.isp-ugc__image.isp-ugc__image--alternate1 {
		& img {
			@include media($smallLayout) {
				width: staticColumnSize(9, 6, $gutter);
			}
		}
	}


	.isp-ugc__image.isp-ugc__image--alternate2 {
		width: 100%;

		& img {
			width: 100%;
		}
	}


	.isp-ugc__image.isp-ugc__image--alternate3 {
		display: inline;

		& img {
			width: auto;
		}
	}

	figcaption {
		@include font(detail);
		color: $colorImgCaption;
		margin-top: $base1 / 2;

		.isp-page--withBg & {
			color: $colorImgCaptionInverted;
		}
	}
}

