.isp-dataFilters {
	flex-grow: 1;
	position: relative;
	z-index: 100;

	@include media($mediumLayout) {
		margin-left: $base2;
	}

	.isp-plot & {
		@include isp-subContainer();
		margin-bottom: $base2;

		@include media($mediumLayout) {
			// margin-left: 0;
			padding-left: $base10;
		}
	}

	.isp-plot.isp-js-showMap & {
		visibility: hidden;
	}


	&__filters {
		left: 0;
		position: absolute;
		top: 100%;
		width: 100%;
		margin-top: $base;

		@include media($mediumLayout) {
			margin-top: 0;
			position: relative;
			top: auto;
		}
	}

	&__filtersItems {
		// @include ease(opacity visibility, $duration2);
		border: 1px solid $colorFilterBorder;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		@include media($mediumLayout) {
			border: 0;
			flex-direction: row;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin-left: -$base2;
			margin-top: -$base2;
			position: relative;
			transition: none;
		}

		// @include media($largeLayout) {
		// 	flex-wrap: nowrap;
		// 	justify-content: flex-end;
		// 	margin-left: 0;
		// }


		&.isp-js-collapsed {
			opacity: 0;
			visibility: hidden;

			@include media($mediumLayout) {
				opacity: 1;
				visibility: inherit;
			}
		}
	}


	&__toggler {
		width: 100%;

		@include media($mediumLayout) {
			display: none;
		}

		.isp-plot & {
			@include media($mediumLayout) {
				@include font(legend);
				font-weight: 600;
				display: inline-flex;
				left: 0;
				position: absolute;
				top: $base2;
				width: $base10;
			}
		}
	}
}
