.isp-editorialPageList {
	@include isp-subContainer();


	&__details {
		@include font(detail);
		color: $colorEditorialPageListDetail;
		margin-top: $base1;
	}


	&__caption {
		@include font(detail);
		color: $colorInverted;
		background-color: transparentize($colorBase, 0.4);
		bottom: 0;
		padding: 0 $base1;
		position: absolute;
	}


	&__image {
		position: relative;
		width: 100%;
		@include media($smallLayout) {
			width: staticColumnSize(6, 9, $gutter);
		}

		@include media($mediumLayout) {
			width: staticColumnSize(3, 9, $gutter);
		}
	}


	&__img {
		width: 100%;
	}



	&__title {
		@include font(base);
	}


	&__link {
		@include isp-link($colorHighlight, $colorHighlightHover);
		display: inline-block;
	}


	&__listItem {
		padding-bottom: $base2;
		padding-top: $base1;

		& + & {
			border-top: solid $colorBorderLight 1px;
		}
	}


	&__lead {
		@include font(detail);
		color: $colorEditorialPageListLead;
	}
}
