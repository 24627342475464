.isp-slide {
	@include ease(opacity visibility, $duration4);
	@include fadeOut();
	outline: 0;
	user-select: text;
	z-index: 1;

	&.isp-js-current {
		@include fadeIn();
		z-index: 100;
	}

	html.no-js &:target {
		@include fadeIn();
		z-index: 101;
	}


	&__caption {
		@include font(detail);
		color: $colorBgBase;
		left: 0;
		padding: $base 0 0 0;
		position: absolute;
		text-align: right;
		top: 100%;
		width: 100%;
	}


	&__content {
		// @include isp-container();
		// @include staticColumns();
		align-items: flex-end;
		height: 100%;
		// padding-bottom: $base3 + $galleryNavigationIconSize + $base3;
		position: relative;
		z-index: 2;

		@include media($largeLayout) {
			padding-bottom: $base5;
		}
	}


	&__image {
		backface-visibility: hidden;
		height: 80vh;
		bottom: 0;
		left: 50%;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: 100%;
	}


	&__picture {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;


		// .isp-modal & {
		// 	flex-grow: 1;
		// }


		// .isp-gallery--slideshow & {
		// 	display: block;
		// 	height: 100%;
		// 	position: relative;
		// 	width: 100%;
		// 	z-index: 1;
		// }

		.isp-gallery--slideshow & + & {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}
	}


	&__textBox {
		// @include isp-coverTextBox();
	}
}
