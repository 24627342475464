.isp-pageMenu {
	@mixin hideOnSticky() {
		@include ease(opacity visibility, $duration4);
		pointer-events: auto;

		.isp-body.isp-js-pageReport .isp-block--pageMenu.isp-js-sticky & {
			@include media($largeLayout) {
				opacity: 0;
				pointer-events: none;
				visibility: hidden;
			}
		}

		.isp-body.isp-js-pageReport .isp-block--pageMenu.isp-js-sticky:hover &,
		.isp-body.isp-js-pageReport .isp-block--pageMenu.isp-js-sticky:focus-within & {
			@include media($largeLayout) {
				opacity: 1;
				pointer-events: auto;
				visibility: inherit;
			}
		}
	}

	$layoutBreakPoint: $largeLayout;

	@include ease(visibility opacity background-color, $pageMenuDuration);
	margin: 0;
	width: 100%;

	@include media($layoutBreakPoint) {
		padding: $base5 0 0 0;
		width: staticColumnSize(3, 12, $gutter);
	}

	.isp-body.isp-js-pageReport & {
		@include media($largeLayout) {
			height: 100vh;
		}
	}


	&--search {
		@include media($layoutBreakPoint) {
			padding-top: 0;
			height: auto;
		}
	}

	.isp-body.isp-js-pageReport .isp-block--pageMenu.isp-js-sticky & {
		@include media($largeLayout) {
			pointer-events: none;
		}
	}


	.isp-body.isp-js-pageReport .isp-block--pageMenu.isp-js-sticky:hover &,
	.isp-body.isp-js-pageReport .isp-block--pageMenu.isp-js-sticky:focus-within & {
		@include media($largeLayout) {
			background-color: $colorInverted;
		}
	}

	&:hover,
	&:focus-within {
		@include media($largeLayout) {
			pointer-events: auto;
		}
	}

	&.isp-js-beforeActive {
		@include fadeOut();
	}

	&.isp-js-duringActive {
		background-color: $colorInverted;
		border: solid 1px $colorHighlight;
		// max-height: $pageMenuOverlaySpacing;
		overflow: hidden;
		overflow-y: scroll;
		padding-left: 0;
		padding-right: 0;
		max-height: 100%;
	}

	&.isp-js-active {
		@include fadeIn();
	}


	&__button {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: center;
		left: 0;
		position: absolute;
		top: $base2;

		@include media($smallLayout) {
			flex-direction: column;
			top: $base5;
		}

		@include media($largeLayout) {
			display: none;
		}

		.isp-pageMenu--search & {
			top: -$base;

			@include media($smallLayout) {
				top: 0;
			}
		}

		.isp-pageMenu.isp-js-active & {
			left: 1rem;
			margin-top: $base1;
			position: sticky;
			top: $base1;
			z-index: 1;
		}
	}


	&__description {
		@include font(detail);
		display: none;
		font-weight: bold;
		padding-left: $base2;
		padding-right: $base2;

		.isp-pageMenu.isp-js-active & {
			display: block;
		}
	}


	&__nav {
		@include ease(visibility opacity, $pageMenuDuration);
		@include font(detail);
		text-transform: uppercase;
		color: $colorPageMenuIcon;
		align-items: center;


		@include media($smallLayout) {
			flex-direction: column;
		}

		@include media($largeLayout) {
			display: none;
		}

		@include onHover('.isp-pageMenu__button', '&') {
			color: $colorPageMenuIconActive;

			span[data-type="svg"] {
				fill: $colorPageMenuIconActive;
			}
		}


		span[data-type="svg"] {
			fill: $colorPageMenuIcon;
		}

		&--closeIcon {
			@include fadeOut();
			display: none;

			.isp-pageMenu.isp-js-beforeActive & {
				display: flex;
			}

			.isp-pageMenu.isp-js-active & {
				@include fadeIn();
			}
		}

		&--navIcon,
		&--index {
			@include fadeIn();
			display: flex;

			.isp-pageMenu.isp-js-beforeActive & {
				@include fadeOut();
			}

			.isp-pageMenu.isp-js-active & {
				display: none;
			}
		}


		&--index {
			border-bottom: solid 1px $colorHighlight;
			position: absolute;
			left: $base5;
			top: 50%;
			transform: translateY(-50%);
			padding-bottom: $base1 / 4;
			margin-left: $base1;

			@include media($smallLayout) {
				position: relative;
				left: 0;
				top: unset;
				transform: translate(0);
				margin-left: 0;
				margin-top: $base1;
			}

			.isp-body.isp-js-pageReport .isp-block--pageMenu.isp-js-sticky & {
				@include fadeOut();
			}
		}
	}


	&__icon {
		@include hideOnSticky();
		color: $colorPageMenuIdentifier;
		display: flex;
		margin-left: $base1;
		position: absolute;
		right: 0;
		top: getCalculatedLineHeight(pageMenu) / 2 - getFontSize(pageMenu) / 2;

		@include media($smallLayout) {
			top: getCalculatedLineHeight(pageMenu, $smallLayout) / 2 - getFontSize(pageMenu, $smallLayout) / 2;
		}

		@include media($largeLayout) {
			top: getCalculatedLineHeight(pageMenu, $largeLayout) / 2 - getFontSize(pageMenu, $largeLayout) / 2;
		}

		.isp-pageMenu__link.isp-js-toggled & {
			transform: rotate(180deg);
		}
	}


	&__item {
		// @include isp-linkLines('&:not(.isp-pageMenu__item--withSubMenu)');
		@include font(pageMenu);
		position: relative;
		padding-right: $base2;
		// pointer-events: auto;

		@include onHover() {
			&::before {
				background-color: $colorPageMenuHover;
			}
		}


		.isp-pageMenu__items--main > & {
			margin-left: -$base5;
			padding-left: $base5;
		}

		&--withSubMenu {
			@include ease(height, $durationCollapsable);
			overflow: hidden;
		}

		.isp-pageMenu__items--main > &::after {
			content: '';
			background: transparent;
			width: $base5;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			pointer-events: auto;
		}
	}


	&__items {
		&--main {
			@include storeValuesForJs((enabled: false));
			display: none;
			padding: $base2;

			@include media($layoutBreakPoint) {
				@include storeValuesForJs((enabled: true));
				background-color: unset;
				display: block;
				padding: 0;
			}

			.isp-pageMenu.isp-js-active & {
				padding-top: $base1;
				display: block;
			}
		}

		&--nested {
			display: flex;
			flex-direction: column;
			opacity: 1;
			padding: 0;
			position: relative;

			&.isp-js-collapsed {
				position: absolute;
				opacity: 0;
				visibility: hidden;
			}
		}
	}


	&__link {
		color: $colorBase;
		display: block;
		position: relative;
		text-decoration: none;

		@include onHover('.isp-pageMenu__item', '&') {
			color: $colorPageMenuHover;
		}

		.isp-pageMenu:not(.isp-pageMenu--search) &::before {
			@include ease(height background-color, $duration2);
			content: '';
			background: $colorPageMenuIdentifier;
			width: $base3;
			height: 1px;
			left: -$base3 - $base2;
			position: absolute;
			visibility: hidden;

			top: getCalculatedLineHeight(pageMenu) / 2;

			@include media($smallLayout) {
				visibility: inherit;
				top: getCalculatedLineHeight(pageMenu, $smallLayout) / 2;
			}

			@include media($largeLayout) {
				top: getCalculatedLineHeight(pageMenu, $largeLayout) / 2;
			}
		}

		.isp-pageMenu:not(.isp-pageMenu--search) &--nested::before {
			content: '';
			width: $base3 / 2;
			left: -$base3 / 2 - $base2;
		}

		.isp-pageMenu:not(.isp-pageMenu--search) &.isp-js-marked::before {
			height: $pageMenuMarkedHeight;
			background-color: $colorHighlight;
		}

		&--toggler {
			display: flex;
			position: relative;

			@include onHover('.isp-pageMenu__item', '&:not(.isp-js-toggled)') {
				color: $colorPageMenuHover;
			}

			@include onHover('&.isp-js-toggled') {
				color: $colorPageMenuHover;
			}

			@include onHover('&', '.isp-pageMenu__title::before') {
				background-color: $colorPageMenuHover;
			}
		}
	}


	&__menuLink {
		color: $colorBase;
		text-decoration: none;

		@include onHover() {
			color: $colorPageMenuHover;
		}

		.isp-footer__menuItems.isp-js-collapsed & {
			user-select: none;
		}
	}


	&__linkLabel {
		@include ease(color font-weight visibility opacity, $duration4);
		@include hideOnSticky();
		@include font(pageMenu);
		display: block;
		flex-grow: 1;
		padding-bottom: $base;
		pointer-events: none;

		.isp-pageMenu__link--nested & {
			padding-left: $base2;
		}

		.isp-pageMenu--search & {
			@include font(base);
		}

		.isp-pageMenu--search .isp-pageMenu__item:first-child & {
			font-weight: 600;
		}

		.isp-pageMenu__link.isp-js-marked & {
			color: $colorBase;
		}

		.isp-pageMenu__link:not(.isp-pageMenu__link--nested) & {
			padding-right: $base2;
		}
	}
}
