.isp-pagination {
	padding-top: $base4;

	@include media($smallLayout) {
		padding-top: $base6;
	}


	&__item {
		display: inline-block;
		height: 100%;
		margin: 0 $baseHalf;

		@include media($smallLayout) {
			margin: 0 $base;
		}

		&--arrowPrev {
			float: left;
			margin-left: 0;
		}

		&--arrowNext {
			float: right;
			margin-right: 0;
		}
	}


	&__items {
		@include isp-subContainer();
		font-size: 0;	// remove the space between inline-block items
		height: $base6;
		padding-top: $base3;
		text-align: center;
		border-top: 1px solid $colorDecoration;
	}
}
