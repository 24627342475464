.isp-sync {
	position: relative;
	min-height: 50vh;


	&::before {
		@include font(base);
		align-items: center;
		background-color: $colorBgBase;
		color: $colorHighlight;
		content: 'loading...';
		display: flex;
		flex-direction: row;
		font-weight: 600;
		justify-content: center;
		left: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;
	}

	&.isp-js-ready::before {
		display: none;
	}


	&__action {
		& + & {
			margin-left: $base2;
		}
	}


	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}


	&__info {
		@include font(detail);
		color: $colorSyncItemInfo;
		width: 100%;

		.isp-sync:not(.isp-js-running) & {
			display: none;
		}
	}


	&__itemInfo {
		color: $colorSyncItemInfo;
		width: 100%;
	}


	&__itemName {
		font-weight: normal;
		width: staticColumnSize(10, 12, $gutter);
	}


	&__itemStatus {
		text-align: right;
		text-transform: uppercase;
		width: staticColumnSize(2, 12, $gutter);
	}


	&__progress {
		margin-top: $base2;
		position: relative;
		overflow: hidden;

		.isp-sync:not(.isp-js-running) & {
			display: none;
		}
	}


	&__progressBar {
		@include ease(transform, $duration2);
		background-color: $colorHighlight;
		height: $base2;
		left: -100%;
		position: relative;
		width: 100%;
	}


	&__progressValue {
		@include font(h3);
	}


	&__section {
		margin-top: $base2;


		&--tasks {
			.isp-sync:not(.isp-js-running) & {
				display: none;
			}
		}
	}


	&__sectionItem {
		@include font(detail);
		padding: $baseHalf 0;
		border-top: 1px solid $colorBorderLight;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}


	&__sectionTitle {
		@include font(h3);
	}


	&__sync {
		flex-grow: 1;
	}


	&__title {
		@include font(h2);
	}
}
